import React from 'react'
import styled from 'styled-components'
import SEO from 'structure/seo'
import Layout from 'structure/layout'

function Page404 () {
  return (
    <Layout noFooter>
      <SEO
        url='/404'
      />
      <Wrapper>
        <Inner>
          <Title>404</Title>
          <Text>
            Não há nada aqui <span role='img' aria-label='Sad'>🙁</span>
          </Text>
        </Inner>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const Inner = styled.div`
  margin: -2em 0 0 0;
  padding: 0;
  text-align: center;
`

const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 12rem;
  letter-spacing: -.05em;
  text-indent: -.02em;
`

const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: 2rem;
  font-weight: normal;
`

export default Page404
